<template>
  <div>
    <p class="pl-3 pb-3" style="font-weight: bold;margin-bottom: 8px;">{{ $t(`label.searchDate`) }}</p>
    <v-row v-if="$vuetify.breakpoint.xsOnly" class="pb-4">
      <v-col cols="6">
        <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRangeText"
              clearable
              @click:clear="clearFilter()"
              outlined
              class="date-search ml-3 elevation-0"
              hide-details="true"
              v-on="on"
              append-icon="search"
            ></v-text-field>
          </template>
          <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-else class="pb-4">
      <v-col cols="4">
        <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" transition="scale-transition" offset-y style="border-radius: 15px;">
          <template v-slot:activator="{ on }">
            <v-text-field v-model="dateRangeText" clearable @click:clear="clearFilter()" outlined class="date-search ml-3 elevation-0" hide-details="true" v-on="on"></v-text-field>
          </template>
          <v-date-picker color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <v-btn @click="filterListByDate()" class="searchBtn">
          search
        </v-btn>
      </v-col>
    </v-row>
    <div class="referral-history">
      <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12">
          <v-data-table light :headers="headers" :items="filteredReports" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr>
                <th
                  class="referral-table-header text-start black--text"
                  style="background-color:#F3F5F6; padding: 10px 16px"
                  v-for="(head, index) in props.headers"
                  :class="{ 'first-cell': index === 0, 'last-cell': index === props.headers.length - 1 }"
                  :key="head.text"
                >
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'">arrow_drop_down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'">arrow_drop_up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.updated_at | longDate }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.downline_membercode }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.commission_amount | currency(currentCurrency) }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  <v-icon color="red" class="mb-1" small v-if="item.status == 'failed'">cancel</v-icon>
                  <v-icon color="green" class="mb-1" small v-if="item.status == 'success'">check_circle</v-icon>
                  <v-icon color="yellow" class="mb-1" small v-if="item.status == 'pending'">watch_later</v-icon>
                  {{ item.status }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" v-if="filteredReports.length == 0" class="text-center pt-0 pb-8">
          <label class="referral-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
        </v-col>
      </v-row>
      <v-row class="mt-1" v-if="pagination.last_page > 1">
        <v-col cols="12" class="text-center">
          <v-pagination
            class="trxn_history_pagination"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="changePage"
            :next-icon="'arrow_forward_ios'"
            :prev-icon="'arrow_back_ios'"
            :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import deleteIcon from '@/assets/svg/delete-icon.svg'
import { formValidator, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_REPORT } from '@/store/referral.module'
import _ from 'lodash'

export default {
  name: 'redeemHistory',
  components: {
    deleteIcon
  },
  data: () => ({
    dateOfBirthMenu: false,
    currentCurrency: uiHelper.getCurrency(),
    language: uiHelper.getLanguage(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    headers: [
      {
        text: 'date',
        align: 'start',
        sortable: false,
        value: 'member_id'
      },
      {
        text: 'memberCode',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'commission',
        value: 'commission',
        sortable: false
      },
      {
        text: 'status',
        value: 'status',
        sortable: false
      }
    ],
    reports: [],
    filteredReports: [],
    selectedPage: 1,
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    }
  }),
  created() {
    this.metaTag()
    this.getReferralReports()
  },
  computed: {
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        return startDate + ' - ' + endDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    referralReports() {
      return this.$store.state.referral.reports.data
    },
    referralReportsPagination() {
      return this.$store.state.referral.reports.pagination
    }
  },
  watch: {
    referralReports() {
      this.reports = this.$store.state.referral.reports.data
      this.filterListByDate()
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        setTimeout(function() {
          this.dateOfBirthMenu = false
        }, 500)
      }
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    getReferralReports() {
      if (this.isLoggedIn) {
        let data = {
          page: this.selectedPage,
          itemsPerPage: 10
        }
        this.$store.dispatch(`${REFERRAL_GET_REPORT}`, { data })
      }
    },
    filterListByDate() {
      if (this.dates[0] && this.dates[1]) {
        var startDate = new Date(this.dates[0])
        var endDate = new Date(this.dates[1])
        this.filteredReports = this.reports.filter(function(r) {
          let date = new Date(r.created_at)
          return date >= startDate && date <= endDate
        })
        this.pagination = this.referralReportsPagination
      } else {
        this.filteredReports = _.cloneDeep(this.reports)
        this.pagination = this.referralReportsPagination
      }
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      this.getReferralReports()
    },
    clearFilter() {
      this.dates = []
      this.filterListByDate()
    }
  }
}
</script>

<style lang="scss">
.daterangepicker .calendars-container {
  min-width: 290px;
  max-width: 290px;
  top: 600px;
  left: 918px;
  transform-origin: left top;
  z-index: 8;
}
.date-search {
  .v-input__slot {
    min-height: 40px !important;
  }
  .v-input__append-inner {
    margin-top: 8px;
  }

  &.v-text-field--outlined fieldset {
    border-color: #d3d3d3 !important;
  }
}
.referral-table-header {
  color: #acacac;
}
.member-id-btn {
  background-color: transparent !important;
  span {
    color: #3a3a3a !important;
    text-decoration: underline;
  }
}
.report-expansion-panel.v-expansion-panels {
  font-size: 14px !important;
  color: #3a3a3a !important;
  .v-expansion-panel-header {
    font-size: 14px !important;
    color: #3a3a3a;
    padding: 5px;
  }
  .expansion-panel-content {
    margin: 0px 15px;
    border: 1px solid #3a3a3a;
    border-radius: 5px;
    background-color: #ebebeb;
    .v-expansion-panel-content__wrap {
      padding: 10px;
    }
  }
}
</style>
